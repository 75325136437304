import React, { useState } from 'react';
import './App.css';

function App() {
  const [showForm, setShowForm] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    serviceType: '',
    details: '',
    preferredTime: '',
    contactInfo: { name: '', email: '' }
  });

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      contactInfo: {
        ...formData.contactInfo,
        [name]: value
      }
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await fetch('https://www.adamfixar.se/save_contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      console.log('Form data submitted successfully');
      alert('Tack för din förfrågan! Vi kommer att kontakta dig snart.');
      setShowForm(false);
    } else {
      console.error('Form submission failed');
      alert('Något gick fel. Försök igen senare.');
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    alert('Något gick fel. Försök igen senare.');
  }
};


  return (
    <div className="App" lang="sv">
          
      <header className="header">
        <div className="container">
          <div className="logo">
            <h1>Adam Fixar</h1>
            <p>Hjälp i vardagen</p>
          </div>
          <nav>
            <ul>
              <li><a href="#about">Om oss</a></li>
              <li><a href="#services">Tjänster</a></li>
              <li><a href="#pricing">Priser</a></li>
              <li><a href="#contact" onClick={() => setShowForm(true)}>Kontakt</a></li>
            </ul>
          </nav>
        </div>
      </header>

      <section className="hero">
        <div className="container">
          <h2>Snabb hjälp när något krånglar – Adam Fixar på nolltid!</h2>
          <button onClick={() => setShowForm(true)} className="cta-btn">Kontakta oss nu</button>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <h2>Välkommen till Adam Fixar – Din lokala problemlösare!</h2>
          <p>Adam Fixar är ett nytt företag som brinner för att hjälpa dig när saker krånglar – och vi gör det snabbt! Med en passion för att hitta kreativa och praktiska lösningar är vårt mål att alltid finnas till hands och erbjuda hjälp som är anpassad för dina behov.</p>
          <p>Vi kombinerar expertis inom fastighetsservice och teknisk support för att kunna lösa problem – oavsett om det är en trasig lampa eller ett IT-problem som behöver fixas.</p>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container">
          <h2>Våra Tjänster</h2>
          <div className="service-list">
            <div className="service-item">
              <h3>Fastighetsservice</h3>
              <ul>
                <li>Reparation av trasiga installationer (lampor, kontakter, lås etc.)</li>
                <li>Underhåll av trädgård och grönytor</li>
                <li>Snöröjning och halkbekämpning</li>
                <li>Fönsterputsning och rengöring av fasader</li>
                <li>Småreparationer och underhåll inomhus och utomhus</li>
              </ul>
            </div>
            <div className="service-item">
              <h3>Teknisk Support</h3>
              <ul>
                <li>Installation och felsökning av nätverk</li>
                <li>Hjälp med WiFi-täckning och anslutningsproblem</li>
                <li>Reparation och uppgradering av datorer och annan IT-utrustning</li>
                <li>Rådgivning kring tekniska inköp för hem och kontor</li>
                <li>Felsökning och problemlösning av smarta hem-enheter</li>
              </ul>
            </div>
            {/* Ytterligare tjänster kan läggas till här */}
          </div>
        </div>
      </section>

      <section id="pricing" className="pricing">
        <div className="container">
          <h2>Prissättning</h2>
          <ul>
            <li>Fastighetsservice: Från 500 kr per timme</li>
            <li>Teknisk Support: Från 600 kr per timme</li>
            <li>Bygg- och Renoveringstjänster: Från 550 kr per timme</li>
            <li>VVS och El: Från 650 kr per timme</li>
            <li>Flytt- och Transporthjälp: Från 400 kr per timme</li>
            <li>Rådgivning och Anpassning: Fast pris enligt överenskommelse</li>
          </ul>
        </div>
      </section>

      {showForm && (
        <div className="popup-form">
          <div className="form-container">
            <button className="close-btn" onClick={() => setShowForm(false)}>X</button>
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <div className="form-step">
                  <h2>Steg 1: Välj Tjänst</h2>
                  <label>
                    Typ av tjänst
                    <select name="serviceType" value={formData.serviceType} onChange={handleInputChange} required>
                      <option value="">Välj en tjänst</option>
                      <option value="Fastighetsservice">Fastighetsservice</option>
                      <option value="Teknisk Support">Teknisk Support</option>
                      <option value="Bygg och Renovering">Bygg och Renovering</option>
                      {/* Lägg till fler alternativ här */}
                    </select>
                  </label>
                  <button type="button" onClick={handleNextStep} className="cta-btn">Nästa</button>
                </div>
              )}
              {step === 2 && (
                <div className="form-step">
                  <h2>Steg 2: Beskriv Problemet</h2>
                  <label>
                    Detaljer
                    <textarea name="details" value={formData.details} onChange={handleInputChange} rows="4" required></textarea>
                  </label>
                  <button type="button" onClick={handlePrevStep} className="cta-btn">Föregående</button>
                  <button type="button" onClick={handleNextStep} className="cta-btn">Nästa</button>
                </div>
              )}
              {step === 3 && (
                <div className="form-step">
                  <h2>Steg 3: Välj Tidpunkt</h2>
                  <label>
                    Önskad tidpunkt för service
                    <input type="text" name="preferredTime" value={formData.preferredTime} onChange={handleInputChange} required />
                  </label>
                  <button type="button" onClick={handlePrevStep} className="cta-btn">Föregående</button>
                  <button type="button" onClick={handleNextStep} className="cta-btn">Nästa</button>
                </div>
              )}
              {step === 4 && (
                <div className="form-step">
                  <h2>Steg 4: Kontaktinformation</h2>
                  <label>
                    Namn
                    <input type="text" name="name" value={formData.contactInfo.name} onChange={handleContactInfoChange} required />
                  </label>
                  <label>
                    E-post
                    <input type="email" name="email" value={formData.contactInfo.email} onChange={handleContactInfoChange} required />
                  </label>
                  <button type="button" onClick={handlePrevStep} className="cta-btn">Föregående</button>
                  <button type="submit" className="cta-btn">Skicka</button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}

      <footer>
        <div className="container">
          <p>© 2024 Adam Fixar. Alla rättigheter förbehållna.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
